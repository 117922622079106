<template>
    <div>
        <div class="tabs">
           <ul>
                <li v-for="(tab,index) in tabs" :class="{ 'is-active': tab.isActive }" v-bind:key="index">
                <a :href="tab.href" @click="selectTab(tab)">{{ tab.name }}</a>
            </li>
           </ul>
        </div>

        <div class="tabs-details">
            <slot></slot>
        </div>
    </div>
</template>


<script>
export default {
        
    data() {
        return {tabs: [] };
    },
    
    created() {
        
        this.tabs = this.$children;
        
    },
    methods: {
        selectTab(selectedTab) {
            this.$emit("changeTab",selectedTab.index);
            this.tabs.forEach(tab => {
                tab.isActive = (tab.name == selectedTab.name);
            });
        }
    }

}
</script>
<style>
.tabs a {
    align-items: center;
    border-bottom-color: #dbdbdb;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    color: #828282;
    display: flex;
    justify-content: center;
    margin-bottom: -1px;
    padding: .5em 1em;
    vertical-align: top;
    text-transform: uppercase;
    font-weight : bold;
}
.tabs li {
    display: block;
}
.tabs {
    margin-top: 2rem;
    -webkit-overflow-scrolling: touch;
    align-items: stretch;
    display: flex;
    font-size: 1rem;
    /* justify-content: space-between; */
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
}
.tabs li.is-active a {
    border-bottom-color: #00d1b2;
    color: #00d1b2;
}

.tabs ul {
    align-items: center;
    border-bottom-color: #dbdbdb;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: flex-start;
    padding-left: 0px;
}

</style>