<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover pb-5" body-classes=" table-responsive">
            <template slot="header">
              <div class="row">
                  <div class="col-12">
                      <h4 class="card-title">{{$t('Estadisticas')}}</h4>
                  </div>
              </div>
            </template>
            <div class="mb-5">
                <div id="root"  >
                    <tabs @changeTab="onSelectTab">
                        <tab name="Altas" :selected="true" :index="1">
                            <bar v-if="loader"
                                :options="options" 
                                :chartdata="chartdata"/>
                        </tab>
                        <tab name="Estatus" :index="2">
                            <doughnut v-if="loader2"
                                :options="options" 
                                :chartdata="chartdata"/>
                        </tab>
                        <tab name="Tramitados" :index="3">
                            <horizontal-bar v-if="loader3"
                                :options="options" 
                                :chartdata="chartdata"/>
                        </tab>
                        <tab name="Usuarios" :index="4">
                            <horizontal-bar v-if="loader4"
                                :options="options" 
                                :chartdata="chartdata"/>
                        </tab>
                        <tab name="Fechas" :index="5">
                            <div class="col-md-4">
                                <label class="control-label">Rango de Fecha</label>
                                <ejs-daterangepicker :format="'d/MM/yyyy'"  @change="onChangeRange" :showClearButton="false"   :maxDays="max" :firstDayOfWeek="day" v-model="dateRange"   :placeholder="waterMark" ></ejs-daterangepicker>
                            </div>
                            <line-chart v-if="loader5"
                                :options="options" 
                                :chartData="chartdata"/>
                        </tab>
                        <tab name="Carpetas" :index="6">
                            <horizontal-bar v-if="loader6"
                                :options="options" 
                                :chartdata="chartdata"/>                            
                        </tab>
                    </tabs>
                </div>                
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { apiCallList } from '../../mixins'
import bar from "@/components/Charts/Bar.vue"
import HorizontalBar from "@/components/Charts/HorizontalBar.vue"
import doughnut from "@/components/Charts/Doughnut.vue"
import LineChart from "@/components/Charts/LineChart.vue"
import Tabs from "@/components/Tabs/Tabs.vue"
import Tab from "@/components/Tabs/Tab.vue"
export default {
    mixins: [apiCallList],
     
    data(){
        return {
            endpoint : "chart_document_reg",
            loader : false,
            loader2 : false,
            loader3 : false,
            loader4 : false,
            loader5 : false,
            loader6 : false,
            keyTab : 1,
            options: null,
            chartdata: null,
            startVal : new Date(),
            endVal : new Date(),
            dateRange : [this.$moment().subtract(1, "days").toDate(),this.$moment().add(1, "days").toDate()],
            day : 1,
            max : 30,
            waterMark : 'Seleccione el rango de fechas',
            endpointChars : {
                1 : "chart_document_reg",
                2 : "chart_document_status",
                3 : "chart_document_status_diff",
                4 : "chart_document_status_users",
                6 : "chart_document_users_folder",
            }
        }
    },
    components : {
        bar,
        doughnut,
        Tabs,
        LineChart,
        HorizontalBar,
        Tab
    },
    methods : {
        loadItems(){
             switch(this.keyTab){
                case 1: 
                    this.options = {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        },
                        legend: {
                            display: false,
                        },
                        title: {
                            display: true,
                            text: 'Alta de Documentos'
                        }
                    },
                    this.chartdata= {
                        labels: ['Enero', 'Febrero',"Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"],
                        datasets: [
                            {
                                label: 'DOCUMENTOS',
                                data : this.data,
                                backgroundColor: [
                                    'rgba(255, 99, 132, 0.5)',
                                    'rgba(54, 162, 235, 0.5)',
                                    'rgba(255, 206, 86, 0.5)',
                                    'rgba(75, 192, 192, 0.5)',
                                    'rgba(153, 102, 255, 0.5)',
                                    'rgba(255, 159, 64, 0.5)'
                                ],
                            },
                        ]
                    }
                    this.loader = true;
                    break;
                case 2:
                    this.options = {
                        responsive: true,
                        maintainAspectRatio: false,
                        
                        title: {
                            display: true,
                            text: 'Estatus de Documentos'
                        }
                    },
                    this.chartdata= {
                        labels: ["DESCARGADO","CONFORME","REVISADO","L/A","S/P"],
                        datasets: [
                            {
                                label: 'DOCUMENTOS',
                                data : [
                                    this.data.donwload,
                                    this.data.conforme,
                                    this.data.revisado,
                                    this.data.archivar,
                                    this.data.pendientes,
                                ],
                                backgroundColor: [                      
                                    'rgba(0, 105, 215, 0.9)',
                                    'rgba(215, 119, 215, 0.9)',
                                    'rgba(75, 192, 192, 0.9)',
                                    'rgba(255, 159, 64, 0.9)',
                                    'rgba(215, 18, 83, 0.9)',
                                    'rgba(76, 180, 80, 0.9)',
                                ],
                            },
                        ]
                    }
                    this.loader2 = true;
                    break;
                case 3:
                    this.options = {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        },
                      
                        title: {
                            display: true,
                            text: 'Documentos Tramitados'
                        }
                    },
                    this.chartdata= {
                        labels: ["DESCARGADO","CONFORME","REVISADO","L/A"],
                        datasets: [
                            {
                                label: 'Con Estatus',
                                data : [
                                    this.data.donwload,
                                    this.data.conforme,
                                    this.data.revisado,
                                    this.data.archivar,
                                    this.data.pendientes,
                                ],
                                backgroundColor: 'rgba(76, 180, 80, 0.9)',

                            },
                                   {
                                label: 'Sin Estatus',
                                data : [
                                    this.data.total - this.data.donwload,
                                    this.data.total - this.data.conforme,
                                    this.data.total - this.data.revisado,
                                    this.data.total - this.data.archivar,
                                    this.data.total - this.data.pendientes
                                ],                         
                            },
                        ]
                    }
                    this.loader3 = true;
                    break;
                case 4:
                    this.options = {
                        responsive: true,
                        maintainAspectRatio: this.data.labels.length > 10 ? true : false,
                        scales: {
                            xAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        },
                      
                        title: {
                            display: true,
                            text: 'Documentos Tramitados'
                        }
                    },
                    this.chartdata= {
                        labels: this.data.labels,
                        datasets: this.data.datasets
                    }    
                    this.loader4 = true;
                    break;
                case 5:
                    this.loader5 = false;
                    this.options = {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        },
                     
                        title: {
                            display: true,
                            text: 'Documentos por fechas'
                        }
                    },
                    this.chartdata= {
                        labels: this.data.labels,
                        datasets: [
                            {
                                label: 'DESCARGADO',
                                data : this.data.datasets.donwload,
                                backgroundColor : "rgba(255, 255, 255, 0.1)",
                                borderColor: "rgba(0, 105, 215, 0.9)"                             
                            },                      
                            {
                                label: 'CONFORME',
                                data : this.data.datasets.conforme,
                                backgroundColor : "rgba(255, 255, 255, 0.1)",
                                borderColor: 'rgba(255, 159, 64, 0.9)',
                            },
                              {
                                label: 'REVISADO',
                                data : this.data.datasets.revisado,
                                backgroundColor : "rgba(255, 255, 255, 0.1)",
                                borderColor: 'rgba(75, 192, 192, 0.9)',
                            },
                              {
                                label: 'L/A',
                                data : this.data.datasets.archivar,
                                backgroundColor : "rgba(255, 255, 255, 0.1)",
                                borderColor: 'rgba(215, 18, 83, 0.9)',
                            }
                        ]
                    }
                    this.loader5 = true;
                    break
                case 6: 
                    this.options = {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        },
                        legend: {
                            display: false,
                        },
                        title: {
                            display: true,
                            text: 'Documentos por Clientes'
                        }
                    },

                    this.chartdata= {
                        labels: this.data.labels,
                        datasets:   [{
                                label: 'DOCUMENTOS',
                                data : this.data.items,
                                backgroundColor: this.data.backgrounds                            
                        }],
                       
                    }    
                    this.loader6 = true;
                    break;
            }                    
        },
        onSelectTab(key){
            this.keyTab = key;
            if(key == 5)
                this.endpoint = "chart_document_status_dates?from="+this.$moment(this.dateRange[0]).format("YYYY-MM-DD")+"&to="+this.$moment(this.dateRange[1]).format("YYYY-MM-DD");            
            else
                this.endpoint = this.endpointChars[key];

            this.fetch();
        },
        onChangeRange(){         
            this.endpoint = "chart_document_status_dates?from="+this.$moment(this.dateRange[0]).format("YYYY-MM-DD")+"&to="+this.$moment(this.dateRange[1]).format("YYYY-MM-DD");
            this.fetch();
        }
    }
}
</script>
<style>
.chartjs-render-monitor {
    min-height: 600px !important;
}
.e-daterange-hidden{
    display: none;
}
.e-input-group .e-input-group-icon, .e-input-group.e-control-wrapper .e-input-group-icon {
    background: #fbfbfb;
    border-color: #ccc;
    color: #7c57ee;
}
</style>